import React from "react"
import { BaseLayout } from "../components/BaseLayout"
import { Copy } from "../components/Copy"
import { Heading } from "../components/Heading"
import { Nav } from "../components/Nav"
import { SeoHead } from "../components/SeoHead"
import { motion } from "framer-motion"
import { CONTENT } from "../contants"

const About = ({ data }) => {
  return (
    <BaseLayout>
      <SeoHead title={`${CONTENT.title} | About us`} />
      <Nav />
      <Heading />
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1, delay: 0.4 }}
      >
        <Copy display="h4" type="div">
          <Copy
            display="h3"
            style={{ marginBottom: 16 }}
            type="h3"
            color="sand"
          >
            About project
          </Copy>
          <p style={{ marginBottom: 32 }}>
            <a
              href="https://prayforganja.com"
              target="_blank"
              style={{ color: "white", fontWeight: "bold" }}
            >
              "Pray for Ganja"
            </a>{" "}
            and{" "}
            <a
              href="https://prayforbarda.com"
              target="_blank"
              style={{ color: "white", fontWeight: "bold" }}
            >
              "Pray for Barda"
            </a>{" "}
            projects have been launched by two friends that wanted to perpetuate
            the names of the killed civilians in Ganja and Barda, to collect
            money for affected victims and to share this tragedy with the
            international community. The project is self-funded and runs
            independently.
          </p>
          <Copy
            display="h3"
            style={{ marginBottom: 16 }}
            type="h3"
            color="sand"
          >
            Project creators
          </Copy>
          <p style={{ marginBottom: 32 }}>
            Shamil Mustafayev - Medior Process Engineer from Ganja. Currently
            living in Rotterdam, the Netherlands and working for one of the
            biggest IT consultancy firms in the world. He is also a co-founder
            and the leader of “Milli Dəstə” supporters group that supports the
            national football team of Azerbaijan, its clubs and sportsmen in
            Europe.
            <br />
            <br />
            You may contact him via Facebook:{" "}
            <a
              href="https://www.facebook.com/mustafayev.shamil"
              target="_blank"
              style={{ color: "white", textDecoration: "underline" }}
            >
              https://www.facebook.com/mustafayev.shamil
            </a>
          </p>
          <p style={{ marginBottom: 32 }}>
            Orkhan Jafarov - Senior Frontend Engineer from Ganja. Currently
            living in Ganja, Azerbaijan and working remotely on EpicEasing
            project based in San Francisco, CA, USA. He was also co-founder of
            social projects such as Sovqat, Acqalma and he has led the team of
            developers in creating and maintaining the website of the local TV
            channel KepezTV.
            <br />
            <br />
            You may contact him via Facebook:{" "}
            <a
              href="https://www.facebook.com/orkhanjafarovr"
              target="_blank"
              style={{ color: "white", textDecoration: "underline" }}
            >
              https://www.facebook.com/orkhanjafarovr
            </a>
          </p>
        </Copy>
      </motion.div>
    </BaseLayout>
  )
}

export default About
